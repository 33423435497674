<template>
  <div class="listNoData">
    <figure class="noDataBox">
      <img class="noDataImg" src="@/assets/img/newsCenter/icon_newscenter_bag@2x.png" />
    </figure>
    <h3 class="noDataTitle">No Notice</h3>
  </div>
</template>
<script>
export default {
  name: 'ListNoData',
  data () {
    return {

    }
  }
}
</script>
<style lang="scss" scoped>
//没数据样式
.listNoData {
  position: relative;
  margin-top: 47.5px;
  padding-bottom: 20px;
  .noDataBox {
    width: 65px;
    height: 0;
    overflow: hidden;
    padding-bottom: 65px;
    margin: 0 auto;
    position: relative;
    .noDataImg {
      width: 100%;
      object-fit: cover;
    }
  }
  .noDataTitle {
    text-align: center;
    font-size: 17.5px;
    font-family: Helvetica;
  }
}
</style>
