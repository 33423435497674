<template>
  <div class="newsCenterBox">
    <!-- 消息列表 -->
    <ul class="iconList">
      <li class="iconItem" v-for="item in newsList" :key="item.id">
        <img class="iconImg" :src="item.imgUrl" />
        <div v-html="item.title" class="iconTitle"></div>
      </li>
    </ul>
    <!-- 推荐数据 -->
    <div class="recommendBox">
      <h3 class="recommendTtitle">Recommended For You</h3>
      <list-no-data />
    </div>
  </div>
</template>
<script>
import ListNoData from '@components/home/ListNoData.vue'
export default {
  name: 'NewsCenter',
  components: {
    ListNoData
  },
  data () {
    return {
      newsList: [
        {
          id: 0,
          imgUrl: require('@/assets/img/newsCenter/icon_newscenter_ponews@2x.png'),
          title: 'PO</br>News'
        },
        {
          id: 1,
          imgUrl: require('@/assets/img/newsCenter/icon_newscenter_transactionnew@2x.png'),
          title: 'Transaction</br>News'
        },
        {
          id: 2,
          imgUrl: require('@/assets/img/newsCenter/icon_newscenter_deliverynews@2x.png'),
          title: 'Delivery</br>News'
        },
        {
          id: 3,
          imgUrl: require('@/assets/img/newsCenter/icon_newscenter_cartingnews@2x.png'),
          title: 'Carting</br>News'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.newsCenterBox {
  position: relative;
  background-color: #ffffff;
  min-height: 100vh;
  .iconList {
    // padding: 0 24.5px;
    box-sizing: border-box;
    padding-top: 21.5px;
    justify-content: space-between;
    border-bottom: 8px solid #f3f4f4;
    @include publicUl;
    @include publicFlex;
    .iconItem {
      width: 93.75px;
      text-align: center;
      padding-bottom: 25px;
      
      .iconImg {
        width: 40px;
        height: 40px;
        object-fit: cover;
      }
      .iconTitle {
        margin-top: 13.5px;
        text-align: center;
        font-size: 12px;
        color: #262930;
        font-family: Helvetica;
        line-height: 17.5px;
      }
    }
  }
  .recommendBox {
    position: relative;
    .recommendTtitle {
      margin: 0;
      padding: 0;
      margin-top: 17.5px;
      font-size: 15px;
      font-family: Helvetica;
      padding: 0 15px;
      box-sizing: border-box;
    }
  }
}
</style>
